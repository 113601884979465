.cardWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2em;

  background: #ffffff;
  box-shadow: 0px 4px 15px #59933125;
  border-radius: 15px;
}
