.react-calendar {
  width: 100%;
  border: none;
  font-family: Helvetica, sans-serif;
  font-size: 0.9em;
  .react-calendar__navigation {
    margin-bottom: 1.5em;
  }
  .react-calendar__navigation {
    button {
      border-radius: 10px;
      margin: 0 0.2em;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .react-calendar__tile:disabled {
    opacity: 0.25;
  }
  .react-calendar__tile--now {
    background-color: #006edc;
    color: #fff;
    div {
      color: #006edc;
    }
  }
  .react-calendar__tile {
    border-radius: 10px;
  }
  .react-calendar__tile--active {
    background: #4b8a2d !important;
    div {
      color: #fff;
    }
  }
  .react-calendar__month-view__weekdays {
    color: #4b8a2d;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #006edc;
  }
}
