.summary {
  text-align: left;
  margin-bottom: 2em;

  .summary-title {
    font-weight: bold;
    font-size: 0.8em;
    color: #599331;
    width: 100%;
    margin-top: 2em;
  }
  label {
    opacity: 0.5;
  }
  .summary-value {
    font-weight: bold;
  }
}
