$base-color: #599331;
$second-color: #737b7d;
$third-color: #a6b3c3;
html,
body {
  background: rgba(194, 194, 181, 20%);
}
h1,
h2,
h3 {
  line-height: 1;
}
.App {
  background: url("../static/gtnBackIcon.svg") bottom left no-repeat
    rgba(194, 194, 181, 20%);
  font-family: "Roboto", sans-serif;
  color: $second-color;

  input {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }
  label {
    font-size: 0.8em;
    span {
      font-size: 1.1em;
      color: rgb(167, 27, 27);
    }
  }
  .content-side-wrap {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #599331;
      border-right: 2px solid rgba(194, 194, 181, 0.2);
      border-radius: 8px;
    }
  }
}
