.button {
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: 0.8em;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em;

  background: #ffffff;
  box-shadow: 0px 4px 15px #59933125;
  border-radius: 12px;

  cursor: pointer;
  &:hover {
    background: #599331;
    box-shadow: none;
    color: #fff;
  }

  &.green {
    background: #599331;
    border: 1px solid #fff;
    color: #fff;
    &:hover {
      background: #ffffff;
      color: #599331;
      border: 1px solid #599331;
      box-shadow: none;
      .iconBox {
        background: transparent;
        color: #599331;
      }
    }
  }
}
