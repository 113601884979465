.image-side {
  background: #599331;
  background-size: cover;
  .image-side-pattern {
    width: 100%;
    height: 100%;
    background: url("/static/ballsBackground.svg") bottom left no-repeat
      transparent;
    background-size: cover;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
