.activity-overview {
  margin-top: 2em;
  h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 1em;
    color: #599331;
    padding: 0;
    margin: 0;
  }
  .local {
    color: #a6b3c3;
    margin-bottom: 0;
  }
  p {
  }
  .description {
    // text-overflow: ellipsis;
    // height: 8vh;
    // overflow: hidden;
  }
}
