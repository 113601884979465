.iconBox {
  height: 25px;
  background: #599331;
  width: 25px;
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.3em;
}
